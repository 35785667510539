import React from 'react';
import {graphql} from 'gatsby';

import {useLocale} from '@app/hooks';

import {AuthorCard, Breadcrumb, Layout, Share} from '@app/components';

import tamBirGozde from '@app/assets/images/authors/tam-bir-gozde.webp';
import sametSezen from '@app/assets/images/authors/samet-sezen.webp';
import recepCifci from '@app/assets/images/authors/recep-cifci.webp';
import mirayOzcicek from '@app/assets/images/authors/miray-ozcicek.webp';
import mitzaCagla from '@app/assets/images/authors/mitza-cagla.webp';
import ozgeAkar from '@app/assets/images/authors/ozge-akar.webp';
import baharTornaci from '@app/assets/images/authors/bahar-tornaci.webp';
import laleKemaliGonzales from '@app/assets/images/authors/lale-kemali-gonzales.webp';
import melisOzcan from '@app/assets/images/authors/melis-ozcan.webp';
import merihTombul from '@app/assets/images/authors/merih-tombul.webp';
import tayfunGuven from '@app/assets/images/authors/tayfun-guven.webp';
import guneyCinar from '@app/assets/images/authors/guney-cinar.webp';
import ismailEroglu from '@app/assets/images/authors/ismail-eroglu.webp';
import tulaySamlioglu from '@app/assets/images/authors/tulay-samlioglu.webp';
import noImageAvailable from '@app/assets/images/authors/noImageAvailable.webp';

const AUTHORS = [
  {
    name: 'Ani Zorbaz',
    profile: '/static/noImageAvailable-7ec9e7d54599b07e3ffc33d64ffee31e.webp',
    onlyTurkish: true,
  },
  {
    name: 'Aslı Cimilli',
    profile: '/static/noImageAvailable-7ec9e7d54599b07e3ffc33d64ffee31e.webp',
    onlyTurkish: true,
  },
  {
    name: 'Ayten Altun',
    profile: '/static/noImageAvailable-7ec9e7d54599b07e3ffc33d64ffee31e.webp',
    onlyTurkish: true,
  },
  {
    name: 'Bahar Tornacı',
    profile: '/static/bahar-tornaci-0e64e8820c33d2262e464b24518ef95b.webp',
    onlyTurkish: false,
  },
  {
    name: 'Berna Bayraktaroğlu',
    profile: '/static/noImageAvailable-7ec9e7d54599b07e3ffc33d64ffee31e.webp',
    onlyTurkish: true,
  },
  {
    name: 'Berna Özer',
    profile: '/static/noImageAvailable-7ec9e7d54599b07e3ffc33d64ffee31e.webp',
    onlyTurkish: false,
  },
  {
    name: 'Başak Kaya',
    profile: '/static/noImageAvailable-7ec9e7d54599b07e3ffc33d64ffee31e.webp',
    onlyTurkish: true,
  },
  {
    name: 'Faruk Sancak',
    profile: '/static/noImageAvailable-7ec9e7d54599b07e3ffc33d64ffee31e.webp',
    onlyTurkish: true,
  },
  {
    name: 'Fulya Bulur',
    profile: '/static/noImageAvailable-7ec9e7d54599b07e3ffc33d64ffee31e.webp',
    onlyTurkish: false,
  },
  {
    name: 'Füsun Fesli',
    profile: '/static/noImageAvailable-7ec9e7d54599b07e3ffc33d64ffee31e.webp',
    onlyTurkish: true,
  },
  {
    name: 'Gülşah Özçakır',
    profile: '/static/noImageAvailable-7ec9e7d54599b07e3ffc33d64ffee31e.webp',
    onlyTurkish: true,
  },
  {
    name: 'Güney Çınar',
    profile: '/static/guney-cinar-9813103aa09dec2d7133e3d6558f796a.webp',
    onlyTurkish: false,
  },
  {
    name: 'İsmail Eroğlu',
    profile: '/static/ismail-eroglu-5f8b241e8a7dd5d9d3b31e0dc001489c.webp',
    onlyTurkish: false,
  },
  {
    name: 'Lale Kemali Gonzales',
    profile:
      '/static/lale-kemali-gonzales-f87aaf004d1a0da25151ad0a63bb885f.webp',
    onlyTurkish: true,
  },
  {
    name: 'Melis Özcan',
    profile: '/static/melis-ozcan-b6b935dd4b601c86601bf1ba19d1e737.webp',
    onlyTurkish: false,
  },
  {
    name: 'Merih Tombul',
    profile: '/static/merih-tombul-70286a9cb34094d72c4d7374ae381ecd.webp',
    onlyTurkish: false,
  },
  {
    name: 'Miray Özçiçek',
    profile: '/static/miray-ozcicek-a8c568aa60cf3487d097cbcdf550507a.webp',
    onlyTurkish: true,
  },
  {
    name: 'Mitza Cagla',
    profile: '/static/mitza-cagla-e41319381318b7a80ff0abc20167d152.webp',
    onlyTurkish: true,
  },
  {
    name: 'Müge Çevik',
    profile: '/static/noImageAvailable-7ec9e7d54599b07e3ffc33d64ffee31e.webp',
    onlyTurkish: true,
  },
  {
    name: 'Özge Akar',
    profile: '/static/ozge-akar-2106799f38cece58c16748e615442b55.webp',
    onlyTurkish: false,
  },
  {
    name: 'Recep Çiftçi',
    profile: '/static/recep-cifci-1f9ad93c7fdfd76b14cdda29c6379aec.webp',
    onlyTurkish: false,
  },
  {
    name: 'Samet Sezen',
    profile: '/static/samet-sezen-46e0cd2e5ec7a4a9caa9c4d0687733bf.webp',
    onlyTurkish: false,
  },
  {
    name: 'Seda Başkent',
    profile: '/static/noImageAvailable-7ec9e7d54599b07e3ffc33d64ffee31e.webp',
    onlyTurkish: true,
  },
  {
    name: 'Selda Kılıç',
    profile: '/static/noImageAvailable-7ec9e7d54599b07e3ffc33d64ffee31e.webp',
    onlyTurkish: false,
  },
  {
    name: 'Serap Balı',
    profile: '/static/noImageAvailable-7ec9e7d54599b07e3ffc33d64ffee31e.webp',
    onlyTurkish: false,
  },
  {
    name: 'Simge Ecemsu Soysal',
    profile: '/static/noImageAvailable-7ec9e7d54599b07e3ffc33d64ffee31e.webp',
    onlyTurkish: false,
  },
  {
    name: 'Tam bir Gözde',
    profile: '/static/tam-bir-gozde-38de7d8d4d85b82a85c2af7739bfaca6.webp',
    onlyTurkish: false,
  },
  {
    name: 'Taner Yalçın',
    profile: '/static/noImageAvailable-7ec9e7d54599b07e3ffc33d64ffee31e.webp',
    onlyTurkish: true,
  },
  {
    name: 'Tayfun Güven',
    profile: '/static/tayfun-guven-31c48538daa13bd55d17b663bb6d5c59.webp',
    onlyTurkish: false,
  },
  {
    name: 'Tuncay Belen',
    profile: '/static/noImageAvailable-7ec9e7d54599b07e3ffc33d64ffee31e.webp',
    onlyTurkish: true,
  },
  {
    name: 'Tülay Şamlıoğlu',
    profile: '/static/tulay-samlioglu-916e6eb1399011d6b468148200c6c73a.webp',
    onlyTurkish: false,
  },
  {
    name: 'Zeynep Bulgulu Asrar',
    profile: '/static/noImageAvailable-7ec9e7d54599b07e3ffc33d64ffee31e.webp',
    onlyTurkish: false,
  },
];

const Authors: React.FC = () => {
  const SCOPE_OPTIONS = {
    scope: 'pages.Authors',
  };
  const {t, language} = useLocale();

  return (
    <Layout authors>
      <Breadcrumb title={t('labels.metaTitle', SCOPE_OPTIONS)} />
      <div className="container mx-auto px-4 py-20 lg:px-0">
        <div className="grid lg:grid-cols-3 lg:gap-x-48 gap-y-10 mt-20">
          {AUTHORS.map((author) => {
            {
              return (
                ((author.onlyTurkish && language == 'tr') ||
                  !author.onlyTurkish) && (
                  <AuthorCard
                    name={author.name}
                    profile={author.profile}
                    key={author.name}
                  />
                )
              );
            }
          })}
        </div>
      </div>
      <div className="py-4">
        <Share title={t('labels.metaTitle', SCOPE_OPTIONS)} />
      </div>
    </Layout>
  );
};

export default Authors;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
